import { FC, useState, ChangeEvent, FocusEvent } from 'react';

import { graphql, navigate } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { GatsbyImage } from 'gatsby-plugin-image';
import { css } from '@emotion/react';
import { useLocation } from '@reach/router';

import { HeadInner } from '../components/Head';
import {
  PostConversionInfo,
  ServiceCode,
  handlePostWithTrackingCV,
} from '../util/post';
import { unixTime } from '../util/unixtime';
import { Layout } from '../components/layout';
import { RegistrationLayout } from '../components/forms/RegistrationLayout';
import { colors } from '../theme/colors';

type HeadProps = {
  data: GatsbyTypes.SeminarTemplateQueryQuery;
};

export const Head: FC<HeadProps> = (props) => {
  const seminar = props.data.contentfulSeminar;
  if (!seminar) {
    return null;
  }
  const coverFile = seminar?.image?.file;
  return (
    <HeadInner
      path={`/seminar/${seminar.slug}`}
      title={seminar.title}
      description={seminar?.description?.description}
      imageUrl={coverFile?.url ? `https:${coverFile.url}` : undefined}
      width={coverFile?.details?.image?.width}
      height={coverFile?.details?.image?.height}
    />
  );
};

const SeminarTemplate: React.FC<{
  data: GatsbyTypes.SeminarTemplateQueryQuery;
}> = (props) => {
  const seminar = props.data.contentfulSeminar;
  if (!seminar) return null;

  const lectures = seminar.lecturers;
  const seminarContent = seminar.seminarContent;

  const location = useLocation();
  const env = process.env.CONTEXT;

  const portalId = process.env.HUBSPOT_ID ? process.env.HUBSPOT_ID : '';
  const formId =
    env === 'dev' || env === 'stg'
      ? 'bea12985-758b-46bd-8454-811141b472fe'
      : 'ca8416c1-a22f-4f19-aa68-baf3794a5a81';

  const [agree, setAgree] = useState(false);

  const handleAgree = () => {
    setAgree(!agree);
  };

  const [form, setForm] = useState<PostConversionInfo>({
    email: '',
    lastname: '',
    firstname: '',
    employees: '',
    department: '',
    jobtitle: '',
    company: '',
    phone: '',
    industry: '',
    seminar_slug: seminar.slug ? seminar.slug : '',
    seminar_question: '',
    seminar_preferred_day:
      seminar.dateOptions && seminar.dateOptions.length > 1
        ? ''
        : unixTime(seminar.dateOptions ? seminar.dateOptions[0] || '' : ''),
    pageUri: location.href,
    registration: false,
    request_document: false,
    request_seminar: true,
    service_codes: seminar.serviceCodes
      ? (seminar.serviceCodes as ServiceCode[])
      : [],
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheck = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePhoneBlur = (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setForm({
      ...form,
      phone: e.currentTarget.value.replace(/-/g, ''),
    });
  };

  const requiredList = [
    'company',
    'email',
    'lastname',
    'firstname',
    'employees',
    'department',
    'phone',
    'industry',
    'seminar_preferred_day',
  ];

  const onPostButtonClick = async () => {
    await handlePostWithTrackingCV(form, portalId, formId, document.title);
    navigate('/seminar/complete/');
  };

  const links = [
    { name: 'セミナー', path: '/seminar/' },
    { name: `${seminar.title}`, path: `/seminar/${seminar.slug}` },
  ];

  return (
    <Layout
      footer='simple'
      background='simple'
      isBottomMenu={false}
      links={links}
    >
      <RegistrationLayout
        form={form}
        onChange={handleChange}
        onCheckBoxChange={handleCheck}
        onSelectChange={handleSelectChange}
        onBlur={handlePhoneBlur}
        agree={agree}
        handleAgree={handleAgree}
        requiredList={requiredList}
        onPostButtonClick={onPostButtonClick}
        dateOptions={seminar.dateOptions}
        isFormSticky
        customPrivacyPolicy={seminar.customPrivacyPolicy?.childMdx?.body}
        isClosed={seminar.isClosed || undefined}
      >
        <div css={SeminarTitleWrapperStyle}>
          <p css={CategoryStyle}>Seminar</p>
          <h2 css={SeminarTitleStyle}>{seminar.title || ''}</h2>
        </div>
        <GatsbyImage
          image={seminar.image?.gatsbyImageData!}
          alt={seminar.title!}
          imgStyle={{ borderRadius: 16 }}
        />
        <section css={SeminarDescriptionSectionStyle}>
          <MDXRenderer>{seminar.description?.childMdx?.body || ''}</MDXRenderer>
        </section>
        {seminarContent && (
          <section css={SectionStyle}>
            <h3 css={SectionTitleStyle}>セミナー内容</h3>
            <ul>
              {seminarContent?.map((content, index) => {
                if (!seminarContent) return <></>;
                return (
                  <li key={index} css={SeminarContentStyle}>
                    {content}
                  </li>
                );
              })}
            </ul>
          </section>
        )}
        {lectures && (
          <section css={SectionStyle}>
            <h3 css={SectionTitleStyle}>登壇者</h3>
            {lectures?.map((lecturer, index) => {
              if (!lecturer) return <></>;
              return (
                <div key={index} css={LecturerWrapperStyle}>
                  {lecturer.image && (
                    <GatsbyImage
                      image={lecturer?.image?.gatsbyImageData!}
                      alt={lecturer?.name || ''}
                      css={LecturerImageStyle}
                    />
                  )}
                  <div css={LecturerNameWrapperStyle}>
                    <p css={LecturerNameStyle}>{lecturer?.name || ''}</p>
                    <p css={LecturerPositionStyle}>
                      {lecturer?.position || ''}
                    </p>
                    <p css={LecturerCareerStyle}>
                      {lecturer?.career?.career || ''}
                    </p>
                  </div>
                </div>
              );
            })}
          </section>
        )}
        {seminar.suggest && (
          <>
            <section css={SectionStyle}>
              <h3 css={SectionTitleStyle}>このような方におすすめ</h3>
              <ul>
                {seminar.suggest?.map((content, index) => {
                  return (
                    <li key={index} css={SeminarContentStyle}>
                      {content}
                    </li>
                  );
                })}
              </ul>
            </section>
          </>
        )}
        <section css={SectionStyle}>
          <h3 css={SectionTitleStyle}>開催概要</h3>
          <div css={SeminarInfoStyle}>
            <div css={SeminarInfoRowStyle}>
              <p css={InfoTitleColumnStyle}>開催日時</p>
              <p css={InfoColumnStyle}>
                {seminar.date?.date || ''}
                {seminar.isClosed && (
                  <span css={{ color: '#ff0000' }}>開催終了しました。</span>
                )}
              </p>
            </div>
            <div css={SeminarInfoRowStyle}>
              <p css={InfoTitleColumnStyle}>会場</p>
              <div css={PlaceColumnStyle}>
                <MDXRenderer>{seminar.place?.childMdx?.body || ''}</MDXRenderer>
              </div>
            </div>
            <div css={SeminarInfoRowStyle}>
              <p css={InfoTitleColumnStyle}>参加費</p>
              <p css={InfoColumnStyle}>{seminar.entryFee?.entryFee || ''}</p>
            </div>
            <div css={[SeminarInfoRowStyle, { borderBottom: 'none' }]}>
              <p css={InfoTitleColumnStyle}>ご注意</p>
              <p css={PlaceColumnStyle}>
                同業他社にあたる企業に所属されている方のご参加はご遠慮いただいております。
              </p>
            </div>
          </div>
        </section>
      </RegistrationLayout>
    </Layout>
  );
};

const SeminarTitleWrapperStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 24,

  '@media(max-width: 1119px)': {
    gap: 16,
  },
});

const CategoryStyle = css({
  color: colors.primary,
  fontSize: 16,

  '@media(max-width: 1119px)': {
    fontSize: 14,
  },
});

const SeminarTitleStyle = css({
  color: colors.black,
  fontSize: 40,

  '@media(max-width: 1119px)': {
    fontSize: 28,
  },
});

const SeminarDescriptionSectionStyle = css({
  p: { fontSize: 16, lineHeight: 2, letterSpacing: 0.05 },

  '@media(max-width: 768px)': {
    p: { fontSize: 14 },
  },
});

const SectionStyle = css({ display: 'flex', flexDirection: 'column', gap: 24 });

const SectionTitleStyle = css({
  color: colors.primary,
  fontSize: 28,
  fontWeight: 700,

  '@media(max-width: 1119px)': {
    fontSize: 24,
  },
});

const LecturerWrapperStyle = css({
  display: 'flex',
  gap: 36,

  '@media(max-width: 1119px)': {
    flexDirection: 'column',
  },
});

const LecturerImageStyle = css({
  width: 200,
  minWidth: 200,
  height: 200,
  borderRadius: 16,

  '@media(max-width: 1119px)': {
    margin: '0 auto',
  },
});

const LecturerNameWrapperStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
});

const LecturerNameStyle = css({
  fontSize: 24,
  fontWeight: 700,
  lineHeight: 1,
  color: colors.black,
});

const LecturerPositionStyle = css({
  fontSize: 13,
  fontWeight: 700,
  lineHeight: 1,
  color: colors.gray,
});

const LecturerCareerStyle = css({
  lineHeight: 2,
  color: colors.black,
});

const SeminarContentStyle = css({
  marginLeft: 28,
  fontSize: 16,
  lineHeight: 2,

  '&::marker': {
    color: colors.primary,
  },

  '@media(max-width: 768px)': {
    fontSize: 14,
  },
});

const SeminarInfoStyle = css({
  borderRadius: 16,
  border: `2px solid ${colors.disabled}`,
  overflow: 'hidden',
});

const SeminarInfoRowStyle = css({
  display: 'flex',
  borderBottom: `1px solid ${colors.disabled}`,
});

const InfoTitleColumnStyle = css({
  display: 'flex',
  alignItems: 'center',
  padding: '32px 40px',
  fontSize: 16,
  fontWeight: 700,
  lineHeight: 1,
  background: '#F9F9F9',
  minWidth: 144,

  '@media(max-width: 1119px)': {
    fontSize: 14,
  },
});

const InfoColumnStyle = css({
  padding: '32px 40px',
  display: 'flex',
  flexDirection: 'column',
  fontSize: 16,
  lineHeight: 1,

  '@media(max-width: 1119px)': {
    fontSize: 14,
  },
});

const PlaceColumnStyle = css({
  padding: '32px 40px',
  display: 'flex',
  flexDirection: 'column',
  fontSize: 16,
  p: { fontSize: 16 },
  lineHeight: 2,

  '@media(max-width: 1119px)': {
    fontSize: 14,
    p: { fontSize: 14 },
  },
});

export const query = graphql`
  query SeminarTemplateQuery($slug: String) {
    contentfulSeminar(slug: { eq: $slug }) {
      image {
        gatsbyImageData
        title
        file {
          url
          details {
            image {
              height
              width
            }
          }
        }
      }
      slug
      title
      suggest
      date {
        date
      }
      dateOptions
      description {
        description
        childMdx {
          body
        }
      }
      entryFee {
        entryFee
      }
      place {
        childMdx {
          body
        }
      }
      customPrivacyPolicy {
        childMdx {
          body
        }
      }
      seminarContent
      lecturers {
        image {
          gatsbyImageData
        }
        name
        position
        career {
          career
        }
      }
      isClosed
      serviceCodes
    }
  }
`;

export default SeminarTemplate;
